import { render, staticRenderFns } from "./LoadSapOldPDF.vue?vue&type=template&id=2c7ea6a2"
import script from "./LoadSapOldPDF.vue?vue&type=script&lang=js"
export * from "./LoadSapOldPDF.vue?vue&type=script&lang=js"
import style0 from "./LoadSapOldPDF.vue?vue&type=style&index=0&id=2c7ea6a2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports